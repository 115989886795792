* {
  box-sizing: border-box;
}


.script-box {
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  background-color: white;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.script-box {
  background: #f0f0f0;
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 100%;
}

.script-box {
  will-change: width, height, left, top;
  position: relative;
}