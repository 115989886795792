

/*body { background: #000; }*/

.orb {
    animation:spin 4s linear infinite;
    width: 300px;
    height: 300px;
    margin: auto;
    margin-top: 140px;
    border-radius: 50%;
    box-shadow:
         0 0 0 50px #fff,
         0 20px 0 60px violet,
         0 -20px 0 60px blue,
         0 20px 0 300px violet,
         0 -20px 0 300px blue,
         0 0 0 50px #fff,
         0 -10px 0 60px violet,
         0 10px 0 60px blue;

    box-shadow:
      0 0 6px 3px #fff,
      0 0 10px 6px #f0f,
      0 0 14px 9px #0ff;

}

@keyframes spin { 100% { -webkit-transform: rotate(360deg); transform:rotate(360deg); } }