* {
  box-sizing: border-box;
}
#rootContainer,
 {
  margin: 0;
  padding: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  user-select: none;
  font-family: -apple-system, BlinkMacSystemFont, avenir next, avenir, helvetica neue, helvetica, ubuntu, roboto, noto,
    segoe ui, arial, sans-serif;
  font-size: 12px;
  font-weight: 600;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #3398db;
}

.rocket {
  background: url('./r.png');
  width: 65px;
  height: 65px;
/*  max-height: 50px;
  overflow: hidden;*/
}

.ship {
  background-size: contain;
  cursor: -webkit-grab;
  will-change: transform;
  user-select: none;
}


.ship:active {
  cursor: -webkit-grabbing;
}


.shielded {
  border-radius: 50%;
  background-color: #fff;
  /*filter: drop-shadow(0.6rem 0.6rem 1rem rgba(20, 20, 180, 0.8));*/
  box-shadow:
    0 0 6px 3px #fff,
    0 0 10px 6px #f0f,
    0 0 14px 9px #0ff;
}



.laser-beam {
  position: absolute;
  left: 46%;
  bottom: 70px;
  width: 3px;
  height: 1000%;
  background: rgba(99, 195, 231, 0.6);
  -webkit-box-shadow: 0px 0px 15px 0px #63c3e7;
  -moz-box-shadow: 0px 0px 15px 0px #63c3e7;
  box-shadow: 0px 0px 15px 0px #63c3e7;
  -moz-transform-origin: 0 100%;
  -ms-transform-origin: 0 100%;
  -webkit-transform-origin: 0 100%;
  transform-origin: 0 100%;
  -webkit-animation: laser 7s infinite;
}
.laser-beam.red {
  -webkit-animation: laser 7.5s infinite;
  background: rgba(236, 19, 65, 0.6);
  -webkit-box-shadow: 0px 0px 15px 0px #ec1341;
  -moz-box-shadow: 0px 0px 15px 0px #ec1341;
  box-shadow: 0px 0px 15px 0px #ec1341;
}
.laser-beam.purple {
  -webkit-animation: laser 7.3s infinite;
  background: rgba(204, 102, 255, 0.6);
  -webkit-box-shadow: 0px 0px 15px 0px #cc66ff;
  -moz-box-shadow: 0px 0px 15px 0px #cc66ff;
  box-shadow: 0px 0px 15px 0px #cc66ff;
}
.laser-beam.green {
  -webkit-animation: laser 7.7s infinite;
  background: rgba(86, 212, 69, 0.6);
  -webkit-box-shadow: 0px 0px 15px 0px #56d445;
  -moz-box-shadow: 0px 0px 15px 0px #56d445;
  box-shadow: 0px 0px 15px 0px #56d445;
}