#boarder {
  background: lightblue;
}

/*html{
    background-color: lightgray;
}

body {
    background-color: #f7edbc;
}*/